<template>
  <div class="content">
    <div class="title">
      详情
    </div>
    <div class="device-box">
      <div class="Pressure">
        <span>设备名称</span>
        <span class="prebox">
          <van-field v-model="fileds.deviceName"
                     ref="area"
                     placeholder="请输入设备名称"/></span>
      </div>
      <div class="Pressure">
        <span>检查保养内容</span>
        <span class="prebox">
          <van-field v-model="fileds.content"
                     ref="area"
                     type="textarea"
                     placeholder="请输入保养内容（用 - 隔开）"/></span>
      </div>
      <div class="Pressure">
        <span>上次保养时间</span>
        <span class="prebox">
          <van-field v-model="fileds.lastTime"
                     ref="area"
                     placeholder="请输入上次保养时间 (xxxx-xx-xx)"/></span>
      </div>
      <div class="Pressure">
        <span>保养周期(天)</span>
        <span class="prebox">
          <van-field v-model="fileds.period"
                     ref="area"
                     placeholder="请输入保养周期"/></span>
      </div>
      <div class="Pressure">
        <span>备注</span>
        <span class="prebox">
          <van-field v-model="fileds.remark"
                     ref="area"
                     placeholder="请输入备注"/></span>
      </div>
    </div>
    <!--    $router.push('/chooseEasy?type=easy')-->
    <div class="choose-model" @click="submit">提交
      <van-icon name="arrow"/>
    </div>
  </div>
</template>
<script>
import service from "@/utils/request";
import {Notify} from "vant";

export default {
  data() {
    return {
      fileds: {
        deviceName: '',
        content: undefined,
        lastTime: '',
        period: '',
        createTime: new Date(),
        timeInterval: 20
      }
    };
  },

  created() {

  },

  methods: {
    submit() {
      if (this.fileds.lastTime){
        if (this.fileds.lastTime.indexOf('-') === -1){
          Notify({ type: 'danger', message: '上次保养时间格式错误，应为xxxx-xx-xx' });
        }
      }
      if (!this.fileds.deviceName) {
        Notify({ type: 'danger', message: '请填写设备名称' });
        return;
      }
      if (!this.fileds.content) {
        Notify({ type: 'danger', message: '请填写检查保养内容' });
        return;
      }
      let latsT = JSON.parse(JSON.stringify(this.fileds.lastTime))
      latsT = Date.parse(latsT);
      let latsC = JSON.parse(JSON.stringify(this.fileds.createTime))
      latsC = Date.parse(latsC);
      if (latsC < latsT) {
        Notify({ type: 'danger', message: '最后一次保养时间应该小于当前时间' });
        return
      }
      //计算两个日期之间相差的毫秒数
      this.fileds.timeInterval = Math.abs(latsC - latsT);
      //毫秒数除以一天的毫秒数,就的到了天数
      this.fileds.timeInterval = Math.floor(this.fileds.timeInterval / (24 * 3600 * 1000));
      service.post('addOrupdate', this.fileds).then(res => {
        if (res.code === 200) {
          Notify({ type: 'success', message: '添加成功' });
          this.$router.push('/details')
        }
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  background: #F2F3F7;
  padding-top: 6rem;
  padding-bottom: 3rem;

  .title {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    position: fixed;
    z-index: 999;
    top: 0;
    width: 100%;
    left: 0;
    background-color: #fff;


    i {
      font-size: 22px;
      font-weight: bold;
      position: absolute;
      top: 13px;
      left: 20px;
    }
  }

  .device-box {
    padding: 1rem 1.5rem;

    .device-item {
      width: 100%;
      border-radius: 1.5rem;
      align-items: center;
      background: #ffffff;
      padding: 1rem 1rem 1rem;

      .device-one {
        height: 3rem;
      }

      span {
        font-size: 1.5rem;
      }

      span:last-child {
        font-size: 1.2rem;
        display: flex;

        i {
          font-size: 2rem;
          margin-left: 1rem;
        }
      }
    }

    .dev-item {
      width: 100%;
      height: 5rem;
      margin-top: 1rem;
      border-radius: 1rem;
      font-size: 1.5rem;
      padding-left: 1.5rem;
      line-height: 5rem;
      background: #F2F3F7;
    }

    .dev-item:last-child {
      margin-bottom: 1rem;
    }

    .Pressure {
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 1rem;
      border-radius: 1.5rem;
      background: #ffffff;
      padding: 0 1rem 2rem;

      span {
        font-size: 1.5rem;
      }

      span:first-child {
        display: block;
        height: 4rem;
        padding-top: 1rem;
      }

      .prebox {
        .van-cell {
          background: #DBE2FE;
          border-radius: 1rem;
          margin-top: 1rem;
          color: #C6C6D0;
          height: 6rem;
        }
      }

      span:last-child {
        font-size: 1.2rem;
        display: flex;

        i {
          font-size: 2rem;
          margin-left: 1rem;
        }
      }
    }
  }
}

.choose {
  background: #DBE2FE !important;
}

.isShow {
  i {
    -moz-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    transition: all .3s;
  }
}

.ishidden {
  i {
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transition: all .3s;
  }
}

.gitBtn {
  width: 32rem;
  height: 5rem;
  font-size: 1.5rem;
  margin: 2rem auto;
  border-radius: 1rem;
  background-image: linear-gradient(90deg,
      #96b6f6 0%,
      #8c76ff 100%),
  linear-gradient(
          #fff5f4,
          #fff5f4);
  color: #ffffff;
  text-align: center;
  line-height: 5rem;
}

.choose-model {
  width: 30rem;
  margin: 2rem auto;
  height: 4rem;
  background-image: linear-gradient(0deg,
      #B940FC 0%,
      #7F30FB 100%),
  linear-gradient(
          #fff5f4,
          #fff5f4);
  border-radius: 3rem;
  color: #ffffff;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    margin-left: 1rem;
  }
}
</style>
