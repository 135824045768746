module.exports = {

    title: 'h5模板',

    /**
     * token头
     */
    tokenHead: 'Bearer ',
    /**
     * 认证请求头
     */
    authorization: 'Authorization'

}
