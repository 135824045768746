import { loginByUsername, queryUserInfo, refreshTokenApi } from '@/api/index'
import { getToken, setToken, getRefreshToken, setRefreshToken } from '@/utils/auth'
const defaultSettings = require('../../settings.js')

const state = {
    token: getToken(),
    refreshToken: getRefreshToken(),

}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_REFRESH_TOKEN: (state, refreshToken) => {
        state.refreshToken = refreshToken
    },
}

const actions = {
    // 用户名密码登录
    userLogin({ commit }, userInfo) {
        const { phone, password } = userInfo
        return new Promise((resolve, reject) => {
            loginByUsername({ phone: phone.trim(), password }).then(response => {
                const token = defaultSettings.tokenHead + response.data.access_token
                const refreshToken = response.data.refresh_token
                commit('SET_TOKEN', token)
                commit('SET_REFRESH_TOKEN', refreshToken)
                setToken(token)
                setRefreshToken(refreshToken)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    getRefreshToekn({ commit }) {
        let sendData = {
            refreshToken: getRefreshToken(),
        }
        return new Promise(resolve => {
            refreshTokenApi(sendData).then(response => {
                const token = defaultSettings.tokenHead + response.data.access_token
                commit('SET_TOKEN', token)
                setToken(token)
                resolve(response);
            })
        })
    },

    // 获取当前登录用户信息
    getUserInfo({ commit }) {
        return new Promise((resolve, reject) => {
            queryUserInfo().then(res => {
                localStorage.setItem('userMsg', JSON.stringify(res.data))
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
