import "@/utils/console" //去掉console打印
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "amfe-flexible"
import "./vantComponents"
import Navigation from 'vue-navigation'
import FastClick from 'fastclick'
import { getToken } from "@/utils/auth"
let loginWhitePath = ['/login', '/limit'];
Vue.use(Navigation, { router })


//初始化FastClick实例。在页面的DOM文档加载完成后
FastClick.attach(document.body)

// 判断登录
let judgeLogin = (to, from, next) => {
  if (getToken()) {
    next()
  } else {
    if (loginWhitePath.indexOf(to.path) != -1) {
      next()
    } else {
      // Notify({ type: "danger", message: "登录已过期，请重新登录！" });
      next('/login')
    }
  }
}

const setHtmlFontSize = () => {
  const htmlDom = document.getElementsByTagName('html')[0];
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
  if (htmlWidth >= 750) {
    htmlWidth = 750;
  }
  if (htmlWidth <= 320) {
    htmlWidth = 320;
  }
  htmlDom.style.fontSize = `${htmlWidth / 37.5}px`;
};
window.onresize = setHtmlFontSize;
setHtmlFontSize();

router.beforeEach((to, from, next) => {

  // if (from.path == '/' && from.name == null && whitePath.indexOf(to.path) == -1) { //保证只有刚进入才调用
  //   let ua = window.navigator.userAgent.toLowerCase();
  //   if (ua.match(/MicroMessenger/i) == 'micromessenger'&&ua.match(/wxwork/i) != 'wxwork') {  //微信环境
  //     judgeLogin(to, from, next);
  //   } else {
  //     next('/limit')
  //   }
  // } else {
  //   judgeLogin(to, from, next);
  // }
  // judgeLogin(to, from, next);
  next()
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
