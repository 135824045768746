import request from '@/utils/request'

// 用户名密码登录
export function loginByUsername(data) {
    return request({
        url: '/verifier/loginByPhone',
        method: 'post',
        data
    })
}

// 用户退出
export function logout(data) {
    return request({
        url: '/verifier/logout',
        method: 'post',
        data
    })
}

// 获取当前登录用户信息
export function queryUserInfo() {
    return request({
        url: '/verifier/info',
        method: 'get'
    })
}

// 刷新token
export function refreshTokenApi(data) {
    return request({
        url: '/verifier/refreshToken',
        method: 'get',
        params: data
    })
}
// 获取签名
export function getSignature(data) {
    return request({
        url: '/wx/getSignature',
        method: 'get',
        params: data
    })
}




