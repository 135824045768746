<template>
  <div class="content">
    <div class="title">
      <van-icon name="arrow-left" @click="$router.push('/')"/>
      <span>详情</span>
    </div>
    <div id="result" style="background: #F2F3F7">
      <div class="details" id="result1" v-for="(item,index) in detaList">
        <div class="dcontent-name">
          <div class="info-box">
            <div class="info-item">
              <span>序号</span>
              <span>{{ index + 1 }}</span>
            </div>
            <div class="info-item">
              <span>设备名称</span>
              <span>{{ item.deviceName || '-' }}</span>
            </div>
            <div class="info-item">
              <span>检查保养内容</span>
              <span>
                <span v-for="con in item.content">{{ con }}</span></span>
            </div>
            <div class="info-item">
              <span>上次保养时间</span>
              <span>{{ item.lastTime || '-' }}</span>
            </div>
            <div class="info-item">
              <span>当天时间</span>
              <span>{{ item.createTime }}</span>
            </div>
            <div class="info-item">
              <span>时间间隔(天)</span>
              <span>{{ item.timeInterval || '-' }}</span>
            </div>
            <div class="info-item">
              <span>保养周期(天)</span>
              <span>{{ item.period || '-' }}</span>
            </div>
            <div class="info-item">
              <span>备注</span>
              <span>{{ item.remark || '-' }}</span>
            </div>
          </div>
        </div>
      </div>
          <div style="display: flex; align-items: center; flex-direction: column">
            <div class="btnBox">
              <van-button class="gitBtn"
                          style="margin: auto;"
                          :loading="exportPdfLoading" @click="exportWord">导出Excal
              </van-button>
            </div>
          </div>
    </div>
  </div>
</template>
<script>
import service from "@/utils/request";
import {format} from "date-fns";

export default {
  data() {
    return {
      address: '123',
      exportPdfLoading: false,
      detaList: [],
    };
  },

  created() {
    this.getPageData()
  },

  methods: {
    //export
    exportWord(){
      this.exportPdfLoading = true
      window.location.href = 'http://139.196.92.127:8087/export'
      this.exportPdfLoading = false
    },
    getPageData(){
      service.get('list?current=1&size=100000').then(res => {
        res.data.records.map(r =>{
          if (r.content){
            r.content = r.content.split('-')
          }
          if (r.lastTime){
            r.lastTime = r.lastTime.split('T')[0]
          }
          if (r.createTime){
            r.createTime = r.createTime.split('T')[0]
          }
        })
        this.detaList = res.data.records
      })
    },
  },
};
</script>
<style lang="scss" scoped>
@import "details";

</style>
